import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import party from '@/app/components/generalLedger/party';
import { required } from 'vuelidate/lib/validators';
import URL_UTILITY from '@/app/utility/url.utility';
import CreateNewBill from '../../transaction/billing/createNewBill';
import openKmAuthentication from '../../../onlineApproval/internalWorkFlow/openKmAuthentication';
import billingPlan from '../../../fms/masterData/billingPlanDetails';
export default {
  name: 'createSalesAgreement',
  props: [
    'salesAgreementDetails',
    'wfSalesAgreementDetails',
    'eventId',
    'wf_inprocess_dtl_id'
  ],
  components: {
    party,
    DatePicker,
    CreateNewBill,
    openKmAuthentication,
    billingPlan
  },
  watch: {
    endDate: function() {
      this.setDafaultDate();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      showValueSetModal: false,
      parent_value_set_id: null,
      depVsetParam: null,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      documentNum: null,
      version: null,
      billCat: {
        value: 'OTH',
        text: 'OTHERS'
      },
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      leaseProject: {
        value: null,
        text: null
      },
      unitType: {
        value: null,
        text: null
      },
      customerPoNum: null,
      unit: {
        value: null,
        text: null
      },
      customer: {
        name: null,
        id: null
      },
      customerSite: {
        name: null,
        id: null
      },
      currency: {
        text: 'INR',
        value: 68
      },
      exchangeRate: 1,
      sharingRatio: 100,
      status: {
        value: 'OPEN',
        text: 'OPEN'
      },
      approvalStatus: {
        value: 'DRAFT',
        text: 'DRAFT'
      },
      clusterHead: {
        value: null,
        text: null
      },
      responsiblePerson: {
        value: null,
        text: null
      },
      saUserType: null,
      defaultValue: {
        value: null,
        text: null
      },
      startDate: null,
      endDate: null,
      quantity: 0,
      salesCompData: [
        {
          bill_hdr_id: 0,
          sales_agreement_comp_id: 0,
          comp_group_id: null,
          comp_group: null,
          comp_id: null,
          comp_name: null,
          billing_type_vset: null,
          billing_vset_meaning: null,
          start_date: this.startDate,
          end_date: this.endDate,
          description: null,
          billing_plan_id: null,
          billing_plan: null,
          workday_hdr_id: null,
          workday_name: null,
          tax_cat_hdr_id: '1',
          tax_category: 'FMS CGST 9% + SGST 9%',
          qty: 1,
          rate: null,
          total_amount: 0,
          uom: null,
          is_client_scope: false
        }
      ],
      initialSalesCompData: [],
      salesCompFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'comp_details'
        },
        {
          key: 'comp_group',
          label: 'Component Group'
        },
        {
          key: 'comp_name',
          label: 'Component Name'
        },
        {
          key: 'description'
        },
        {
          key: 'is_client_scope',
          class: 'text-center'
        },
        {
          key: 'billing_plan'
        },
        {
          key: 'billing_vset_meaning',
          label: 'Billing Type'
        },
        {
          key: 'workday_name',
          label: 'Workday Calendar'
        },
        {
          key: 'qty',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'rate',
          class: 'text-center'
        },
        {
          key: 'unit_area_sharing_ratio',
          label: 'Sharing Ratio',
          class: 'text-center'
        },
        {
          key: 'total_amount',
          class: 'text-center'
        },
        {
          key: 'tax_category'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'last_bill_details'
        },
        {
          key: 'plan_details'
        }
      ],
      salesCompDetailsData: [
        {
          sales_agreement_comp_dtl_id: 0,
          sales_agreement_comp_id: 0,
          employee_code: null,
          employee_name: null,
          asset_desc: null,
          start_date: this.startDate,
          end_date: this.endDate
        }
      ],
      salesCompDetailsFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'employee_code'
        },
        {
          key: 'employee_name'
        },
        {
          key: 'asset_desc',
          label: 'Asset Description'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ],
      showComponentDetailsModal: false,
      rowDetails: null,
      specIndex: null,
      salesAgreementHdrId: 0,
      showCustomerSiteModal: false,
      showPartyModal: false,
      notificationSummary: null,
      isApprovedByCurrrentUser: false,
      isConsultInitiated: false,
      notificationComments: null,
      urlSummaryData: null,
      wfDtlId: null,
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        }
      ],
      addCase: false,
      totalAmount: 0,
      showBillDetailsModal: false,
      billData: 0,
      approvalHistoryList: [],
      showDownloadModal: false,
      showOpenKmModal: false,
      openkmAuthToken: null,
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ],
      saAgmtHdrId: null,
      // percentValue: null,
      isSummaryTimesheet: true,
      showPlanDetailsModal: false,
      mergedTrx: true,
      billableArea: null,
      isExternal: null,
      showMeterDetailsModal: false,
      meterDetailsData: [],
      meterDetailsFields: [
        {
          key: 'sr_no'
        },
        {
          key: 'meter_code',
          label: 'Meter Type'
        },
        {
          key: 'meter_no',
          label: 'Meter Number'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'fms_prj_name',
          label: 'Project Name'
        },
        {
          key: 'entity_type',
          label: 'Entity Type'
        },
        {
          key: 'unit_name',
          label: 'Unit/Subunit'
        },

        {
          key: 'start_date',
          label: 'Start Date'
        },
        {
          key: 'end_date',
          label: 'End Date'
        },
        {
          key: 'party_type',
          label: 'Party Type'
        },
        {
          key: 'party_name',
          label: 'Party Name'
        },
        {
          key: 'customer_site_name',
          label: 'Party Site'
        },
        {
          key: 'site_address',
          label: 'Site Address'
        },
        {
          key: 'meter_no',
          label: 'Meter No'
        },
        {
          key: 'load',
          label: 'Load'
        },
        {
          key: 'load_uom_meaning',
          label: 'Load UOM'
        },
        {
          key: 'owner_value_meaning',
          label: 'Ownership Type'
        }
      ],
      meterStatus: null,
      selectPlan: {
        text: null,
        value: null
      }
    };
  },
  validations: {
    legalEntity: {
      value: { required }
    },
    operatingUnit: {
      value: { required }
    },
    project: {
      value: { required }
    },
    customer: {
      id: { required }
    },
    customerSite: {
      id: { required }
    },
    currency: {
      value: { required }
    },
    exchangeRate: { required },
    startDate: { required },
    endDate: { required }
  },
  mounted() {
    if (this.salesAgreementDetails) {
      this.salesAgreementHdrId = this.salesAgreementDetails.sales_agreement_hdr_id;
      this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
    } else if (this.wfSalesAgreementDetails) {
      this.salesAgreementHdrId = this.wfSalesAgreementDetails.sales_agreement_hdr_id;
      this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
    } else {
      this.addCase = true;
      this.editMode = true;
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      this.editMode = false;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      // this.menuId = this.urlSummaryData.wf_menu_id;
      this.salesAgreementHdrId = this.urlSummaryData.sales_agreement_hdr_id;
      this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
    } else {
      this.wfDtlId = this.wf_inprocess_dtl_id;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addEditSalesAgreementDetails();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    setDafaultDate() {
      if (this.addCase) {
        this.salesCompData[0].start_date = this.startDate;
        this.salesCompData[0].end_date = this.endDate;
        this.salesCompDetailsData[0].start_date = this.startDate;
        this.salesCompDetailsData[0].end_date = this.endDate;
      }
    },
    getApprovalHistory(salesAgreementHdrId) {
      this.loader = true;
      this.$store.dispatch('auth/getUserDetail').then(response => {
        if (response.status === 200) {
          this.userInfo = response.data.data;
          const payload = {
            approvalStatus: 'Y',
            nfaHdrId: salesAgreementHdrId,
            eventId: this.urlSummaryData
              ? this.urlSummaryData.wf_event_id
              : this.eventId
          };
          this.$store
            .dispatch('intWorkflow/getApprovalList', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.approvalHistoryList = response.data.data;
                const findCurrentUser = this.approvalHistoryList.find(
                  user =>
                    user.approver_employee_id === this.userInfo.employee_id &&
                    (user.approval_status === 'INPROCESS' ||
                      user.approval_status === 'CONSULT_INITIATED')
                );
                this.isApprovedByCurrrentUser = findCurrentUser ? true : false;
                const findConsultInitUser = this.approvalHistoryList.find(
                  data => data.approval_status === 'CONSULT_INITIATED'
                );
                this.isConsultInitiated = findConsultInitUser ? true : false;
              }
            })
            .catch(() => {
              this.loader = false;
            });
        }
      });
    },
    changeStatus(status) {
      this.loader = true;
      const payload = [
        {
          event_id: this.urlSummaryData
            ? this.urlSummaryData.wf_event_id
            : this.eventId,
          notification_comments: this.notificationComments,
          notification_status: status,
          wf_inprocess_dtl_id: this.wfDtlId,
          temp_approved_amount: 0
        }
      ];
      this.$store
        .dispatch('intWorkflow/approvalReject', { approval_requests: payload })
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getApprovalHistory(this.salesAgreementHdrId);
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSalesAgreementDetailsById(hdrId) {
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgreementDetailsById', hdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.billableArea = result.billable_area;
            this.saAgmtHdrId = JSON.parse(
              JSON.stringify(response.data.data.sales_agreement_hdr_id)
            );
            this.initialSalesCompData = JSON.parse(
              JSON.stringify(response.data.data.sales_agree_comp)
            );
            this.setSalesAgreementDetails(result);
            this.countTotalBudgetAmount(result.sales_agree_comp);
          }
        });
    },
    countTotalBudgetAmount(item) {
      let sum = 0;
      item.forEach(data => {
        if (data.total_amount) {
          sum += parseFloat(data.total_amount);
        }
        // if (data.rate && data.uom !== 'PERCENTS') {
        //   sum += parseFloat(data.rate) * parseFloat(data.qty);
        // } else if (data.uom === 'PERCENTS') {
        //   this.percentValue = (sum * data.rate) / 100;
        //   sum += (sum * data.rate) / 100;
        // }
        this.totalAmount = parseFloat(sum).toFixed(2);
      });
    },
    checkSharingRatio() {
      if (this.sharingRatio > 100) {
        alert('Sharing can not be greater than 100');
        this.sharingRatio = null;
      }
    },
    setSalesAgreementDetails(item) {
      this.salesAgreementHdrId = item.sales_agreement_hdr_id;
      this.documentNum = item.document_num;
      this.version = item.version;
      this.legalEntity = {
        value: item.le_id,
        text: item.le_name
      };
      this.operatingUnit = {
        value: item.ou_id,
        text: item.ou_name
      };
      this.project = {
        value: item.fms_project_id,
        text: item.fms_prj_name
      };
      this.leaseProject = {
        value: item.lease_project_id,
        text: item.lease_prj_name
      };
      this.customer = {
        name: item.customer_name,
        id: item.customer_id
      };
      this.customerSite = {
        name: item.customer_site_name,
        id: item.customer_site_id
      };
      this.currency = {
        text: item.currency_name,
        value: item.currency_id
      };
      this.clusterHead = {
        text: item.cl_head_name,
        value: item.user_id
      };
      this.responsiblePerson = {
        text: item.res_person_name,
        value: item.user_id
      };
      this.billCat.text = item.bill_cat_vset_meaning;
      this.billCat.value = item.bill_cat_vset;
      this.status = {
        value: item.status,
        text: item.status_meaning
      };
      this.approvalStatus = {
        value: item.approval_status,
        text: item.approval_status_meaning
      };
      this.unit = {
        value: item.unit_id,
        text: item.unit_name
      };
      this.unitType = {
        value: item.unit_type,
        text: item.unit_type
      };
      this.customerPoNum = item.customer_po_num;
      this.exchangeRate = item.exchange_rate;
      this.startDate = item.start_date;
      this.endDate = item.end_date;
      this.isSummaryTimesheet = item.is_bill_on_timesheet_summary;
      this.mergedTrx = item.is_merged_trx;
      this.isExternal = item.is_external;
      this.sharingRatio = item.unit_area_sharing_ratio;
      this.salesCompData = this.setCompDetails(item);
      if (
        this.$route.name === 'notificationSummary' ||
        this.$route.name === 'dashboard' ||
        this.$route.name === 'workFlowSummary'
      ) {
        this.notificationSummary = true;
        this.getApprovalHistory(this.salesAgreementHdrId);
      }
      if (!this.isExternal) {
        this.salesCompFields.splice(11, 0, {
          key: 'billable_area',
          class: 'text-center'
        });
      }
    },
    setCompDetails(item) {
      let comp = [];
      if (item.sales_agree_comp.length > 0) {
        comp = item.sales_agree_comp.map(data => {
          return {
            bill_hdr_id: data.bill_hdr_id,
            sales_agreement_comp_id: data.sales_agreement_comp_id,
            comp_group_id: data.comp_group_id,
            comp_group: data.comp_group_name,
            comp_id: data.comp_id,
            comp_name: data.comp_name,
            billing_type_vset: data.billing_type_vset,
            billing_vset_meaning: data.billing_vset_meaning,
            description: data.description,
            billing_plan_id: data.billing_plan_id,
            billing_plan: data.billing_plan_name,
            workday_hdr_id: data.workday_hdr_id,
            workday_name: data.workday_calendar_name,
            tax_cat_hdr_id: data.tax_cat_hdr_id,
            tax_category: data.tax_cat_name,
            start_date: data.start_date,
            end_date: data.end_date,
            qty: data.qty,
            rate: data.rate,
            uom: data.uom,
            total_amount: data.total_amount,
            is_client_scope: data.is_client_scope,
            comp_dtls: data.comp_dtls,
            billable_area: data.billable_area,
            unit_area_sharing_ratio: data.unit_area_sharing_ratio
          };
        });
      } else {
        comp = this.salesCompData;
      }
      return comp;
    },
    addEditSalesAgreementDetails() {
      this.validationForDuplicateEntry();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const saleAgreeComp = [];
        for (let i = 0; i < this.salesCompData.length; i++) {
          if (
            this.salesCompData[i].sales_agreement_comp_id !== 0 &&
            this.salesCompData[i].sales_agreement_comp_id ===
              this.initialSalesCompData[i].sales_agreement_comp_id
          ) {
            if (
              this.salesCompData[i].comp_group_id !==
                this.initialSalesCompData[i].comp_group_id ||
              this.salesCompData[i].comp_id !==
                this.initialSalesCompData[i].comp_id ||
              this.salesCompData[i].billing_type_vset !==
                this.initialSalesCompData[i].billing_type_vset ||
              this.salesCompData[i].description !==
                this.initialSalesCompData[i].description ||
              this.salesCompData[i].billing_plan_id !==
                this.initialSalesCompData[i].billing_plan_id ||
              this.salesCompData[i].workday_hdr_id !==
                this.initialSalesCompData[i].workday_hdr_id ||
              this.salesCompData[i].tax_cat_hdr_id !==
                this.initialSalesCompData[i].tax_cat_hdr_id ||
              this.salesCompData[i].start_date !==
                this.initialSalesCompData[i].start_date ||
              this.salesCompData[i].end_date !==
                this.initialSalesCompData[i].end_date ||
              this.salesCompData[i].qty !== this.initialSalesCompData[i].qty ||
              this.salesCompData[i].is_client_scope !==
                this.initialSalesCompData[i].is_client_scope
            ) {
              saleAgreeComp.push(this.salesCompData[i]);
            }
          } else if (this.salesCompData[i].sales_agreement_comp_id == 0) {
            saleAgreeComp.push(this.salesCompData[i]);
          }
        }
        const payload = {
          sales_agreement_hdr_id: this.addCase
            ? 0
            : this.salesAgreementHdrId
            ? this.salesAgreementHdrId
            : this.saAgmtHdrId,
          menu_id: this.$store.state.shared.menuId,
          document_num: this.documentNum,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          fms_project_id: this.project.value,
          lease_project_id: this.leaseProject.value,
          customer_po_num: this.customerPoNum,
          unit_id: this.unit.value,
          unit_type: this.unitType.value,
          customer_id: this.customer.id,
          customer_site_id: this.customerSite.id,
          currency_id: this.currency.value,
          cl_head_id: this.clusterHead.value,
          res_person_id: this.responsiblePerson.value,
          bill_cat_vset: this.billCat.value,
          exchange_rate: this.exchangeRate,
          unit_area_sharing_ratio: this.sharingRatio,
          start_date: this.startDate,
          end_date: this.endDate,
          is_bill_on_timesheet_summary: this.isSummaryTimesheet,
          is_merged_trx: this.mergedTrx,
          sales_agreement_comp: saleAgreeComp ? saleAgreeComp : null
        };
        let checkValidation = true;
        for (const comp of saleAgreeComp) {
          if (
            comp.comp_group_id === null ||
            comp.comp_id === null ||
            comp.billing_type_vset === null ||
            comp.start_date === null ||
            comp.end_date === null ||
            comp.description === null ||
            comp.billing_plan_id === null
          ) {
            alert('Please Fill all the mandatory fields first');
            checkValidation = false;
            return;
          } else if (
            comp.billing_type_vset === 'TIMESHEET' &&
            comp.workday_hdr_id === null
          ) {
            alert('Workday Calander is Mandatory for Billing Type Timesheet');
            checkValidation = false;
            return;
          } else {
            checkValidation = true;
          }
        }
        if (checkValidation) {
          this.loader = true;
          this.$store
            .dispatch('salesAgreement/addEditSalesAgreementDetails', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.salesAgreementHdrId =
                  response.data.data.sales_agreement_hdr_id;
                this.addCase = false;
                this.editMode = false;
                this.isSuccess = true;
                this.showAlert = true;
                this.responseMsg = response.data.message;
                this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
              } else {
                this.showAlert = true;
                this.editMode = false;
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.editMode = false;
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    validationForDuplicateEntry() {
      // let isDuplicate = false;
      // if (this.salesCompData.length > 1) {
      //   for (let i = 0; i < this.salesCompData.length; i++) {
      //     for (let j = i+1; j < this.salesCompData.length; j++) {
      //       if (
      //         this.salesCompData[i].comp_id == this.salesCompData[j].comp_id &&
      //         this.salesCompData[i].billing_plan_id ==
      //           this.salesCompData[j].billing_plan_id &&
      //         this.salesCompData[i].start_date ==
      //           this.salesCompData[j].start_date &&
      //         this.salesCompData[i].end_date == this.salesCompData[j].end_date
      //       ) {
      //         isDuplicate = true;
      //         this.salesCompData[j].comp_id = null;
      //         this.salesCompData[j].comp_name = null;
      //         this.salesCompData[j].billing_plan_id = null;
      //         this.salesCompData[j].billing_plan = null;
      //       }
      //     }
      //   }
      // }
      // if(isDuplicate){
      //   alert('You entered duplicate data in the component.');
      // }
      if (this.salesCompData.length > 1) {
        for (let i = 0; i < this.salesCompData.length; i++) {
          for (let j = 1; j < this.salesCompData.length; j++) {
            if (
              i !== j &&
              this.salesCompData[i].comp_id == this.salesCompData[j].comp_id &&
              this.salesCompData[i].billing_plan_id ==
                this.salesCompData[j].billing_plan_id &&
              this.salesCompData[i].start_date ==
                this.salesCompData[j].start_date &&
              this.salesCompData[i].end_date == this.salesCompData[j].end_date
            ) {
              alert('You entered duplicate data in the component.');
              this.salesCompData[j].comp_id = null;
              this.salesCompData[j].comp_name = null;
              this.salesCompData[j].billing_plan_id = null;
              this.salesCompData[j].billing_plan = null;
            }
          }
        }
      }
    },
    showHideComponentDetailsModal(flag, item) {
      if (item) {
        this.salesCompDetailsData = [
          {
            sales_agreement_comp_dtl_id: 0,
            sales_agreement_comp_id: 0,
            employee_code: null,
            employee_name: null,
            asset_desc: null,
            start_date: this.startDate,
            end_date: this.endDate
          }
        ];
        this.quantity = item.qty;
        const salesAgreementCompId = item.sales_agreement_comp_id;
        this.getSalesAgreementCompDetailsById(salesAgreementCompId);
      }
      this.showComponentDetailsModal = flag;
    },
    showHideBillDetailsModal(flag, item) {
      if (item) {
        this.billData = {
          bill_hdr_id: item.bill_hdr_id,
          bill_type: 'F'
        };
      }
      this.showBillDetailsModal = flag;
    },
    getSalesAgreementCompDetailsById(Id) {
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getSalesAgreementCompDetailsById', Id)
        .then(response => {
          this.loader = false;
          if (response.status === 200 && response.data.data.length > 0) {
            this.salesCompDetailsData = response.data.data;
          }
        });
    },
    validateValueset(vsetCode, index) {
      if (this.salesCompData[index].comp_id) {
        this.openValueSetModal(vsetCode, index);
      } else {
        alert('Please Select Component First');
      }
    },
    openValueSetModal(vsetCode, index, saUserType) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.saUserType = saUserType;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.parent_value_set_id = this.legalEntity.value;
        this.depVsetParam = this.operatingUnit.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.parent_value_set_id = 1;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.parent_value_set_id = this.salesCompData[
          this.specIndex
        ].comp_group_id;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_BY_COMP_ID
      ) {
        this.parent_value_set_id = this.salesCompData[this.specIndex].comp_id;
        this.depVsetParam = this.project.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.depVsetParam = this.project.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GET_PLAN_BY_PRJ_ID_UNIT_TYPE
      ) {
        this.parent_value_set_id = this.project.value;
        this.depVsetParam = this.unitType.value;
        if (this.parent_value_set_id === null) {
          this.$bvToast.toast('Please Select Fms Project first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
          this.showValueSetModal = false;
        }
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.SA_USER) {
        if (this.saUserType === 'clusHead') {
          this.clusterHead = {
            value: item.user_id,
            text: item.employee_name_and_code
          };
        } else if (this.saUserType === 'respPer') {
          this.responsiblePerson = {
            value: item.user_id,
            text: item.employee_name_and_code
          };
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.project = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.LEASE_PRJ_USING_FMS
      ) {
        this.leaseProject = {
          text: item.lease_prj_name,
          value: item.lease_prj_id
        };
        this.project = {
          text: item.fms_prj_name,
          value: item.fms_prj_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.project = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.unitType = {
          text: item.value_meaning,
          value: item.value_code
        };
        this.project = this.defaultValue;
        this.leaseProject = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.unit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unit = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CURRENCY_LIST) {
        this.currency.text = item.value_code;
        this.currency.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.COMPONENT_GRP) {
        this.salesCompData[this.specIndex].comp_group = item.value_code;
        this.salesCompData[this.specIndex].comp_group_id =
          item.value_set_dtl_id;
        this.salesCompData[this.specIndex].comp_name = null;
        this.salesCompData[this.specIndex].comp_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.salesCompData[this.specIndex].comp_id = item.value_set_dtl_id;
        this.salesCompData[this.specIndex].comp_name = item.value_code;
        this.salesCompData[this.specIndex].description = item.value_code;
        this.salesCompData[this.specIndex].uom = item.uom;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RATE_BASIS_VSET) {
        this.salesCompData[this.specIndex].billing_type_vset = item.value_code;
        this.salesCompData[this.specIndex].billing_vset_meaning =
          item.value_meaning;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_BY_COMP_ID
      ) {
        this.salesCompData[this.specIndex].billing_plan_id =
          item.value_set_dtl_id;
        this.salesCompData[this.specIndex].billing_plan = item.value_code;
        this.salesCompData[this.specIndex].rate = item.rate;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WORKDAY_CALENDAR) {
        this.salesCompData[this.specIndex].workday_hdr_id =
          item.value_set_dtl_id;
        this.salesCompData[this.specIndex].workday_name = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.salesCompData[this.specIndex].tax_cat_hdr_id =
          item.value_set_dtl_id;
        this.salesCompData[this.specIndex].tax_category = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_CAT_VSET) {
        this.billCat.text = item.value_meaning;
        this.billCat.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GET_PLAN_BY_PRJ_ID_UNIT_TYPE
      ) {
        this.selectPlan.text = item.billing_plan_name;
        this.selectPlan.value = item.billing_plan_hdr_id;
        this.salesAgreementDefaultPlan();
      }
    },
    clearVsetValues(vsetCode, saUserType) {
      this.vsetCode = vsetCode;
      if (vsetCode === appStrings.VALUESETTYPE.SA_USER) {
        if (saUserType === 'clusHead') {
          this.clusterHead = {
            value: null,
            text: null
          };
        } else if (saUserType === 'respPer') {
          this.responsiblePerson = {
            value: null,
            text: null
          };
        }
      } else if (vsetCode === this.project.value) {
        this.project = this.defaultValue;
        this.leaseProject = this.defaultValue;
        this.selectPlan.text = null;
        this.selectPlan.value = null;
      } else if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
        this.project = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.customer.id) {
        this.customer = { id: null, name: null };
        this.customerSite = { id: null, name: null };
      } else if (vsetCode === this.customerSite.id) {
        this.customerSite = { id: null, name: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CURRENCY_LIST) {
        this.currency = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.PLAN_UNIT_TYPE) {
        this.unitType = { text: null, value: null };
        this.unit = { text: null, value: null };
        this.project = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        this.unit = { text: null, value: null };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        this.unit = { text: null, value: null };
      } else if (vsetCode === this.billCat.value) {
        this.billCat = { text: null, value: null };
      } else if (vsetCode === 'GET_PLAN_BY_PRJ_ID_UNIT_TYPE') {
        this.selectPlan = { text: null, value: null };
      }
    },
    clearVsetValuesOnGrid(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      if (this.vsetCode === appStrings.VALUESETTYPE.COMPONENT_GRP) {
        this.salesCompData[this.specIndex].comp_group = null;
        this.salesCompData[this.specIndex].comp_group_id = null;
        this.salesCompData[this.specIndex].comp_name = null;
        this.salesCompData[this.specIndex].comp_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_COMPONENT) {
        this.salesCompData[this.specIndex].comp_id = null;
        this.salesCompData[this.specIndex].comp_name = null;
        this.salesCompData[this.specIndex].description = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.RATE_BASIS_VSET) {
        this.salesCompData[this.specIndex].billing_type_vset = null;
        this.salesCompData[this.specIndex].billing_vset_meaning = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN_BY_COMP_ID
      ) {
        this.salesCompData[this.specIndex].billing_plan_id = null;
        this.salesCompData[this.specIndex].billing_plan = null;
        this.salesCompData[this.specIndex].rate = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.WORKDAY_CALENDAR) {
        this.salesCompData[this.specIndex].workday_hdr_id = null;
        this.salesCompData[this.specIndex].workday_name = null;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        this.salesCompData[this.specIndex].tax_cat_hdr_id = null;
        this.salesCompData[this.specIndex].tax_category = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    },
    addNewRow(item) {
      if (item.billing_type_vset === null) {
        alert('Please Fill all the mandatory fields first');
      } else if (
        item.billing_type_vset === 'TIMESHEET' &&
        item.workday_hdr_id === null
      ) {
        alert('Workday Calander is Mandatory for Billing Type Timesheet');
      } else {
        if (this.editMode) {
          this.salesCompData.push({
            sales_agreement_comp_id: 0,
            comp_group_id: null,
            comp_group: null,
            comp_id: null,
            comp_name: null,
            billing_type_vset: null,
            billing_vset_meaning: null,
            start_date: this.startDate,
            end_date: this.endDate,
            description: null,
            billing_plan_id: null,
            billing_plan: null,
            workday_hdr_id: null,
            workday_name: null,
            tax_cat_hdr_id: '1',
            tax_category: 'FMS CGST 9% + SGST 9%',
            rate: null,
            is_client_scope: false
          });
        }
      }
    },
    removeRow(index) {
      this.salesCompData.splice(index, 1);
    },
    addNewDetailsRow() {
      if (this.editMode && this.salesCompDetailsData.length < this.quantity) {
        this.salesCompDetailsData.push({
          sales_agreement_comp_dtl_id: 0,
          sales_agreement_comp_id: 0,
          employee_code: null,
          employee_name: null,
          asset_desc: null,
          start_date: this.startDate,
          end_date: this.endDate
        });
      } else if (!this.editMode) {
        alert('Please Enable Edit Mode');
      } else {
        alert("More Employee Can't be added");
      }
    },
    removeDetailsRow(index) {
      this.salesCompDetailsData.splice(index, 1);
    },
    saveDetails() {
      this.showComponentDetailsModal = false;
      const payload = {
        sales_agreement_comp_dtl: this.salesCompDetailsData
      };
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/saveSalesAgreementCompDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.salesAgreementHdrId =
              response.data.data.sales_agreement_hdr_id;
            this.editMode = false;
            this.isSuccess = true;
            this.showAlert = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHidePartyModal(flag) {
      setTimeout(() => {
        this.eventBus.$emit('userType', 'CUST');
      }, 0);
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.customer.name = item.party_name;
      this.customer.id = item.party_id;
      this.customerSite.name = null;
      this.customerSite.id = null;
      this.showPartyModal = false;
      this.getCustomerByPartyId(item.party_id);
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leases/getCustomerByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.customer.id = response.data.data.customer_id;
            this.getCustomerSiteByPartyId();
          }
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.customer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Customer First');
      }
    },
    getCustomerSiteByPartyId() {
      const payload = {
        customer_id: this.customer.id,
        ou_id: this.operatingUnit.value
      };
      this.gridDataSite = [];
      this.$store
        .dispatch('party/getCustomerSiteByOu', payload)
        .then(response => {
          this.gridDataSite = response.data.data.customer_site_lists;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedSite(item) {
      this.customerSite.name = item.site_name;
      this.customerSite.id = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    checkOpenKmPasswordSaved() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.$store.state.shared.menuId
          ? this.$store.state.shared.menuId
          : this.urlSummaryData.wf_menu_id,
        sub_menu_id: 0,
        record_id: this.salesAgreementDetails
          ? this.salesAgreementDetails.sales_agreement_hdr_id
          : this.wfSalesAgreementDetails.sales_agreement_hdr_id
          ? this.wfSalesAgreementDetails.sales_agreement_hdr_id
          : this.urlSummaryData.nfa_hdr_id
      };
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    qtyTwoDecimalValue(qty, index) {
      if (qty) {
        if (qty.charAt(0) === '.') {
          this.salesCompData[index].qty = '0.';
        } else {
          var regex = /^\d+(\.\d{0,2})?$/g;
          if (!regex.test(qty)) {
            alert('Only two decimal places are allowed.');
            const [integerPart, decimalPart] = qty.split('.');
            const truncatedDecimal = parseFloat(
              `${integerPart}.${decimalPart.slice(0, 2)}`
            );
            setTimeout(() => {
              this.salesCompData[index].qty = truncatedDecimal;
            }, 0);
          }
        }
      }
    },
    showHidePlanDetailsModal(flag, item) {
      this.showPlanDetailsModal = flag;
      if (flag) {
        this.$route.params.salesAgreementData = item;
      }
    },
    // salesAgreementDefaultPlan() {
    //   const payload = {
    //     sales_agreement_hdr_id: this.salesAgreementHdrId,
    //     menu_id: this.$store.state.shared.menuId,
    //     document_num: this.documentNum,
    //     le_id: this.legalEntity.value,
    //     ou_id: this.operatingUnit.value,
    //     fms_project_id: this.project.value,
    //     lease_project_id: this.leaseProject.value,
    //     customer_po_num: this.customerPoNum,
    //     unit_id: this.unit.value,
    //     unit_type: this.unitType.value,
    //     customer_id: this.customer.id,
    //     customer_site_id: this.customerSite.id,
    //     currency_id: this.currency.value,
    //     exchange_rate: this.exchangeRate,
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //     is_bill_on_timesheet_summary: this.isSummaryTimesheet,
    //     is_merged_trx: this.mergedTrx,
    //     cl_head_id: this.clusterHead.value,
    //     res_person_id: this.responsiblePerson.value,
    //     unit_area_sharing_ratio: this.sharingRatio,
    //     bill_cat_vset: this.billCat.value
    //   };
    //   this.loader;
    //   this.$store
    //     .dispatch('salesAgreement/salesAgreementDefaultPlan', payload)
    //     .then(response => {
    //       if (response.status === 200) {
    //         this.salesAgreementHdrId =
    //           response.data.data.sales_agreement_hdr_id;
    //         this.isSuccess = true;
    //         this.showAlert = true;
    //         this.addCase = false;
    //         this.responseMsg = response.data.message;
    //         this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
    //       } else {
    //         this.isSuccess = false;
    //         this.showAlert = true;
    //         this.responseMsg = response.response.data.message;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    openMeterDetailsModal() {
      this.showMeterDetailsModal = true;
      this.getFmsMeterDetails();
    },
    showHideMeterDetailsModal(flag) {
      this.showMeterDetailsModal = flag;
    },
    getFmsMeterDetails() {
      const payload = {
        unit_id: this.unit.value,
        unit_type: this.unitType.value
      };
      this.loader = true;
      this.$store
        .dispatch('salesAgreement/getMeterDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.meterDetailsData = response.data.data;
            for (let i = 0; i < this.meterDetailsData.length; i++) {
              this.meterDetailsData[i].sr_no = i + 1;
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return item.status === false ? 'table-danger' : 'table-success';
      
    },
    salesAgreementDefaultPlan() {
      if (this.customer.id === null) {
        this.$bvToast.toast('Please Select customer', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.customerSite.id === null) {
        this.$bvToast.toast('Please Select customerSite', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.operatingUnit.value === null) {
        this.$bvToast.toast('Please Select Operating Unit', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.legalEntity.value === null) {
        this.$bvToast.toast('Please Select Operating Unit', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.project.value === null) {
        this.$bvToast.toast('Please Select Project', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.currency.value === null) {
        this.$bvToast.toast('Please Select Currency', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.exchangeRate === null) {
        this.$bvToast.toast('Please Select Currency', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.startDate === null) {
        this.$bvToast.toast('Please Select Start Date', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else if (this.endDate === null) {
        this.$bvToast.toast('Please Select End Date', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      } else {
        const payload = {
          sales_agreement_hdr_id: this.salesAgreementHdrId,
          menu_id: this.$store.state.shared.menuId,
          document_num: this.documentNum,
          le_id: this.legalEntity.value,
          ou_id: this.operatingUnit.value,
          fms_project_id: this.project.value,
          lease_project_id: this.leaseProject.value,
          customer_po_num: this.customerPoNum,
          unit_id: this.unit.value,
          unit_type: this.unitType.value,
          customer_id: this.customer.id,
          customer_site_id: this.customerSite.id,
          currency_id: this.currency.value,
          exchange_rate: this.exchangeRate,
          start_date: this.startDate,
          end_date: this.endDate,
          is_bill_on_timesheet_summary: this.isSummaryTimesheet,
          is_merged_trx: this.mergedTrx,
          cl_head_id: this.clusterHead.value,
          res_person_id: this.responsiblePerson.value,
          unit_area_sharing_ratio: this.sharingRatio,
          bill_cat_vset: this.billCat.value
        };
        this.loader;
        this.$store
          .dispatch('salesAgreement/salesAgreementDefaultPlan', payload)
          .then(response => {
            if (response.status === 200) {
              this.salesAgreementHdrId =
                response.data.data.sales_agreement_hdr_id;
              this.isSuccess = true;
              this.showAlert = true;
              this.addCase = false;
              this.responseMsg = response.data.message;
              this.getSalesAgreementDetailsById(this.salesAgreementHdrId);
            } else {
              this.isSuccess = false;
              this.showAlert = true;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    }
   },
  beforeDestroy() {
    this.unsubscribe();
  }
};
